* {
  padding: 0;
  margin: 0;
}

:root {
  --bs-white: #fff;
  --bs-dark: #000;
  --Turquoise: #30d5c7;
  --Turquoise-dark: #00978a;
}

body {
  background-color: var(--bs-white);
  color: var(--bs-dark);
  line-height: 24px;
  font-size: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
}

/* Header Start */

.top-nav {
  background-color: #efefef;
  padding: 10px 0;
  border-bottom: 1px solid #dfdfdf;
}
.bg-light {
  background-color: #f8f9fa !important;
  box-shadow: 0 1px 0px var(--Turquoise-dark);
}
.top-box1 {
  display: flex;
  justify-content: end;
  padding-top: 3px;
  align-items: center;
}
.contact-number {
  border-right: 1px solid #1f1f1f;
  padding-right: 10px;
}
.social-media-top {
  margin-left: 10px;
}
.contact-number a {
  font-size: 17px;
  color: #1f1f1f;
  text-decoration: none;
}
.contact-number a:hover {
  color: var(--Turquoise-dark);
  transition: all 0.4s ease-in-out;
}
.social-media-top ul {
  margin: 0;
  padding: 0;
}

.playapp {
  display: flex;
  width: 50%;
  gap: 10px;
}


.play {
  /* display: flex; */
  width: 30%;
  flex-direction: row;
  text-align: center;
  display:flex;
  text-align: center !important;
}
.social-media-top ul li {
  list-style: none;
  display: inline-block;
  padding: 0 5px;
}
.social-media-top ul li a {
  color: #1f1f1f;
  background-color: #e5e5e5;
  padding: 3px 8px 9px 8px !important;
  border-radius: 2px;
}
.social-media-top ul li a i {
  font-size: 14px;
}
.social-media-top ul li a:hover {
  color: var(--Turquoise-dark);
  background-color: var(--bs-white);
  transition: all 0.4s ease-in-out;
}
.head-franchise {
  display: flex;
  justify-content: end;
}
.head-btn {
  color: var(--bs-white) !important;
  background-color: var(--Turquoise-dark) !important;
  transform: skewX(25deg);
  border: 0;
  margin-left: 15px;
  border-radius: 0px !important;
  transition: 400ms;
  padding: 5px 30px;
  font-size: 16px;
}
.head-btn:hover {
  background-color: var(--bs-dark) !important;
  color: var(--bs-white) !important;
}
.head-btn span {
  transform: skewX(157deg);
  display: block;
  font-weight: 600;
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}
.logo {
  width: 8%;
}

.btn1 a {
  font-size: 15px;
  font-weight: bold;
  color: var(--bs-white);
  text-align: center;
  /* background-color:var(--Turquoise-dark); */
  padding: 10px 22px;
  color: #fff;
  text-decoration: none;
  list-style: none;
  border-radius: 5px;
}

.nav-link-one {
  color: var(--bs-white) !important;
  background-color: var(--Turquoise-dark);
  transform: skewX(25deg);
  border: 0;
  margin-left: 15px;
  border-radius: 0px !important;
  transition: 400ms;
  padding: 8px 30px !important;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: normal;
  font-family: "Raleway", sans-serif;
}
.nav-link-one:hover {
  background-color: #009c8f !important;
  color: var(--bs-white) !important;
}
.nav-item1.active {
  color: #000;
  background-color: var(--Turquoise-dark);
}
.nav-link-one span {
  transform: skewX(157deg);
  display: block;
  font-weight: 600;
}
.logo:before {
  left: -45px;
  width: 220px;
  -webkit-transform: skew(30deg);
  -moz-transform: skew(30deg);
  -o-transform: skew(30deg);
  -ms-transform: skew(30deg);
  transform: skew(30deg);
  background-color: var(--Turquoise-dark);
  content: "";
  position: absolute;
  bottom: 0;
  height: 115%;
  z-index: 0;
}
.navbar-brand-one {
  position: relative;
  width: 117%;
  top: -15px;
}
.navbar-brand-one img {
  width: 100%;
}
.number-text {
  margin-right: 10px;
}
.header-nav {
  z-index: 1;
  position: relative;
}

/* Banner */
.header-banner {
  /* background-image: url("../images/banner1.jpg"); */
  /* padding: 100px 0 50px 0; */
  /* height: 100%; */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  background-position: center;
  overflow: hidden;
}
.header-banner::before {
  content: "";
  background-color: var(--bs-white);
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
  top: 0;
  opacity: 0.9;
}
.banner-text h2 {
  font-size: 40px;
}
.banner-text h2 span {
  font-weight: bold;
  color: var(--Turquoise-dark);
}
.banner-text p {
  font-size: 15px;
  padding: 20px 0 40px 0;
  letter-spacing: 1px;
}
.btn1:hover {
  opacity: 0.7;
  transition: all 0.4s ease-in-out;
  transform: scale(1.1);
}
.btn1 a img {
  border: 1px solid var(--bs-dark);
  padding: 2px;
  border-radius: 7px;
}
.banner-men {
  position: absolute;
  top: 20%;
}
.bg-video {
  position: relative;
  z-index: -1;
}
/* Banner */

/* Header End */

/* Middle Start */

.about-men {
  padding: 80px 0;
  background-color: var(--bs-white);
  position: relative;
}
.about-men::before {
  content: "";
  /* background-color: var(--Turquoise-dark); */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  border-top: 520px solid transparent;
  border-right: 670px solid var(--Turquoise-dark);
  border-bottom: 0px solid transparent;
}
.about-text h2 {
  font-size: 30px;
  font-weight: bold;
  color: var(--Turquoise-dark);
}
.about-text p {
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 1px;
}
.welcome-text {
  padding-bottom: 15px;
}

.how-it-works {
  padding: 80px 0;
}
.heading-men {
  text-align: center;
}
.heading-men {
  padding-bottom: 30px;
}
.heading-men h2 {
  font-size: 30px;
  font-weight: bold;
  color: var(--Turquoise-dark);
}
.heading-men p {
  font-size: 18px;
}
.how-works-one {
  text-align: center;
  background-color: var(--bs-white);
  color: #61a7ba;
  padding: 15px 0px 20px 0px;
  /* margin-top: 20px; */
  border-radius: 10px;
  border-right: 5px solid #61a7ba;
  box-shadow: 0 0 10px #e9e9e9;
  cursor: pointer;
  position: relative;
  transition: transform 0.9s, opacity 0.9s;
}
.how-works-one.active {
  transform: scale(1.1);
  opacity: 1;
}
.how-works-one:hover:before {
  content: "";
  padding: 0;
  /* background-image: linear-gradient(120deg, #51c089, #61a7ba); */
  background-color: var(--Turquoise-dark);
  width: 109%;
  position: absolute;
  z-index: -1;
  height: 85%;
  left: -4%;
  top: 8%;
  /* transform: skewX(-25deg); */
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
  box-shadow: 0 0 5px #858585;
}
.how-works-one:hover:after {
  content: "";
  padding: 0;
  /* background-image: linear-gradient(120deg, #51c089, #61a7ba); */
  background-color: var(--Turquoise-dark);
  width: 117%;
  position: absolute;
  z-index: -3;
  height: 65%;
  left: -8%;
  top: 18%;
  /* transform: skewX(-25deg); */
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
  box-shadow: 0 0 5px #858585;
}
.how-works-one:hover {
  background-color: var(--Turquoise-dark);
  color: var(--bs-white);
  transition: all 0.4s ease-in-out;
  border-color: var(--bs-white);
  border-right: 5px solid var(--Turquoise-dark);
  box-shadow: 0 0 5px #858585;
}
.how-works-active img {
  border-color: var(--bs-white) !important;
}
.how-works-one:hover {
  /* background-image: linear-gradient(120deg, #51c089, #61a7ba); */
  background-color: var(--Turquoise-dark);
  color: var(--bs-white);
  transition: all 0.4s ease-in-out;
  border-color: var(--bs-white);
  border: none;
  border-right: 5px solid var(--Turquoise-dark);
}
/* .how-works-rignt:hover {
  border:none;
  border-left: 5px solid #61a7ba;
  background-image: linear-gradient(269deg, #51c089, #61a7ba) !important;
} */
/* .how-works-rignt:hover:before {
  content: '';
  padding: 0;
  background-image: linear-gradient(269deg, #51c089, #61a7ba) !important;
  width: 109%;
  position: absolute;
  z-index: -1;
  height: 85%;
  left: -5%;
  top: 8%;
  border-radius: 10px;
  transition: all .4s ease-in-out;
} */
/* .how-works-rignt:hover:after {
  content: '';
  padding: 0;
  background-image: linear-gradient(269deg, #51c089, #61a7ba) !important;
  width: 117%;
  position: absolute;
  z-index: -3;
  height: 65%;
  left: -9%;
  top: 18%;
  border-radius: 10px;
  transition: all .4s ease-in-out;
}  */
.how-works-one:hover .img-fluid {
  border-color: var(--bs-white);
}
.how-works-one h2 {
  text-transform: capitalize;
  font-size: 16px;
  padding-top: 20px;
  width: 70%;
  margin: 0 auto;
  line-height: 25px;
}
.how-works-one img {
  width: 80px;
  height: 80px;
  border: 1px dashed var(--Turquoise-dark);
  border-radius: 100%;
  padding: 5px;
}
.how-works-rignt {
  border: none;
  border-left: 5px solid var(--Turquoise-dark);
}
.how-works-center:before {
  content: "";
  border: 2px solid #61a7ba;
  position: absolute;
  left: 50%;
  height: 100%;
  text-align: center;
  border-radius: 20px;
}
.how-works-center:after {
  border: 2px solid #61a7ba;
  content: "";
  width: 54%;
  position: absolute;
  top: 9.5%;
  left: -15px;
  z-index: -4;
}
.how-works-center span {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #61a7ba;
  color: var(--bs-white);
  font-size: 12px;
  max-width: 20px;
  max-height: 20px;
  position: absolute;
  line-height: 20px;
  text-align: center;
  top: 8.6%;
  left: 48%;
  z-index: 1;
  font-weight: bold;
}
.how-works-center-two:after {
  border: 2px solid #61a7ba;
  content: "";
  width: 54%;
  position: absolute;
  top: 25%;
  right: -16px;
  z-index: -4;
}
.how-works-center-two span {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #61a7ba;
  color: var(--bs-white);
  font-size: 12px;
  max-width: 20px;
  max-height: 20px;
  position: absolute;
  line-height: 20px;
  text-align: center;
  top: 23.9%;
  left: 48%;
  z-index: 1;
  font-weight: bold;
}
.how-works-center-three:after {
  border: 2px solid #61a7ba;
  content: "";
  width: 54%;
  position: absolute;
  top: 40.7%;
  left: -15px;
  z-index: -4;
}
.how-works-center-three span {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #61a7ba;
  color: var(--bs-white);
  font-size: 12px;
  max-width: 20px;
  max-height: 20px;
  position: absolute;
  line-height: 20px;
  text-align: center;
  top: 39.7%;
  left: 48%;
  z-index: 1;
  font-weight: bold;
}
.how-works-center-for:after {
  border: 2px solid #61a7ba;
  content: "";
  width: 54%;
  position: absolute;
  top: 59.5%;
  right: -15px;
  z-index: -4;
}
.how-works-center-for span {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #61a7ba;
  color: var(--bs-white);
  font-size: 12px;
  max-width: 20px;
  max-height: 20px;
  position: absolute;
  line-height: 20px;
  text-align: center;
  top: 58.5%;
  left: 48%;
  z-index: 1;
  font-weight: bold;
}
.how-works-center-five:after {
  border: 2px solid #61a7ba;
  content: "";
  width: 54%;
  position: absolute;
  top: 73.2%;
  left: -15px;
  z-index: -4;
}
.how-works-center-five span {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #61a7ba;
  color: var(--bs-white);
  font-size: 12px;
  max-width: 20px;
  max-height: 20px;
  position: absolute;
  line-height: 20px;
  text-align: center;
  top: 72.3%;
  left: 48%;
  z-index: 1;
  font-weight: bold;
}
.how-works-center-six:after {
  border: 2px solid #61a7ba;
  content: "";
  width: 54%;
  position: absolute;
  top: 90%;
  right: -15px;
  z-index: -4;
}
.how-works-center-six span {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #61a7ba;
  color: var(--bs-white);
  font-size: 12px;
  max-width: 20px;
  max-height: 20px;
  position: absolute;
  line-height: 20px;
  text-align: center;
  top: 89%;
  left: 48%;
  z-index: 1;
  font-weight: bold;
}
.select-required {
  top: 28%;
}
.confirm-order {
  top: 11%;
}
.how-works-active {
  /* background-image: linear-gradient(120deg, #51c089, #61a7ba); */
  background-color: var(--Turquoise-dark);
  color: var(--bs-white);
  transition: all 0.4s ease-in-out;
  border-color: var(--bs-white);
  border: none;
  border-right: 5px solid var(--Turquoise-dark);
  box-shadow: 0 0 5px #858585;
}
.how-works-active :before {
  content: "";
  padding: 0;
  /* background-image: linear-gradient(120deg, #51c089, #61a7ba); */
  background-color: var(--Turquoise-dark);
  width: 109%;
  position: absolute;
  z-index: -1;
  height: 85%;
  left: -4%;
  top: 8%;
  /* transform: skewX(-25deg); */
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
  box-shadow: 0 0 5px #858585;
}
.how-works-active :after {
  content: "";
  padding: 0;
  /* background-image: linear-gradient(120deg, #51c089, #61a7ba); */
  background-color: var(--Turquoise-dark);
  width: 117%;
  position: absolute;
  z-index: -3;
  height: 65%;
  left: -8%;
  top: 18%;
  /* transform: skewX(-25deg); */
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
  box-shadow: 0 0 5px #858585;
}

.middle-banner {
  padding: 0;
  background-color: var(--Turquoise-dark);
  position: relative;
  overflow: hidden;
}
.middle-banner::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  right: 0%;
  /* background-color: var(--bs-white); */
  /* border-radius: 100%; */
  border-top: 530px solid #ffffff00;
  border-right: 1000px solid #ffffff;
  border-bottom: 0px solid transparent;
  display: none;
}

.our-services-men {
  padding: 80px 0;
}
.our-services-one {
  border: 1px solid var(--Turquoise-dark);
  padding: 20px 20px 30px 20px;
  border-radius: 5px;
  position: relative;
  text-align: center;
  height: 250px;
}
.our-services-one:hover {
  background-color: var(--Turquoise-dark);
  transition: all 0.4s ease-in-out;
  color: var(--bs-white);
}
.our-services-one:hover p {
  color: var(--bs-white);
  transition: all 0.4s ease-in-out;
}
.our-services-one:hover img {
  background-color: var(--bs-white);
  border-radius: 5px;
  padding: 6px;
  transition: all 0.4s ease-in-out;
}
.our-services-one:hover::before {
  content: "";
  background-color: var(--bs-white);
  transition: all 0.4s ease-in-out;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
}
.our-services-one:hover::after {
  content: "";
  background-color: var(--bs-white);
  transition: all 0.4s ease-in-out;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  right: 0;
  top: 0;
}
.our-services-one img {
  width: 20%;
}
.our-services-one h2 {
  text-transform: capitalize;
  font-size: 18px;
  padding-top: 20px;
  font-weight: bold;
}
.our-services-one p {
  font-size: 14px;
  line-height: 20px;
  color: #313131;
  font-weight: 500;
  padding-top: 10px;
}
.our-services-one::before {
  content: "";
  width: 50px;
  height: 50px;
  background-color: var(--Turquoise-dark);
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  border-radius: 4px 0 100% 0;
}
.our-services-one::after {
  content: "";
  width: 50px;
  height: 50px;
  background-color: var(--Turquoise-dark);
  position: absolute;
  z-index: 0;
  right: 0;
  top: 0;
  border-radius: 0 4px 0 100%;
}

.why-chooseus-men {
  padding: 80px 0;
  /* background-image: url('../images/banner1.jpg'); */
  background-color: var(--bs-white);
  position: relative;
}
.why-chooseus-men::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--bs-white);
  opacity: 0.9;
  top: 0;
  left: 0;
  z-index: 0;
}
.chooseus-text {
  position: relative;
  z-index: 1;
}
.why-chooseus-one {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px #dddd;
  border-radius: 5px;
  position: relative;
}
.why-chooseus-one::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  top: 0;
  right: 0;
  background-color: #d1d1d1;
  border-radius: 0 0 0 100px;
}
.why-chooseus-one h2 {
  text-transform: capitalize;
  font-size: 18px;
  padding-top: 15px;
  font-weight: bold;
}
.why-chooseus-one p {
  font-size: 13px;
  line-height: 20px;
  color: #313131;
  padding-top: 5px;
}
.why-chooseus-one-photo img {
  border-radius: 10px;
}

.blog-men {
  padding: 80px 0;
  background-color: #b8edec1f;
}
.blog-men-text {
  background-color: var(--bs-white);
  padding: 15px 15px 25px 15px;
  border-radius: 5px;
  box-shadow: 0 0 10px #e9e9e9;
  transition: all 0.35s ease-in-out;
}
.blog-men-text img {
  border-radius: 5px 5px 0 0;
  object-fit: cover;
  height: 220px;
  width: 100%;
}
.blog-men-text:hover {
  transform: scale(1.1);
}
.left-box {
  display: flex;
  justify-content: space-between;
}
.left-box span a {
  color: var(--Turquoise-dark);
  font-size: 14px;
  text-decoration: none;
}
.blog-box1 p {
  font-size: 13px;
  line-height: 16px;
  color: #333333;
  padding-top: 10px;
}
.head-btn-one {
  padding: 0;
}

.At-fingertips-men {
  padding: 80px 0;
  text-align: center;
  background-image: url("../public/images/app-bg.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 0;
}
.At-fingertips-men::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--bs-white);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.9;
  z-index: -1;
}
.btn2 img {
  width: 70%;
}
.app-icon-photo {
  width: 6%;
  margin-bottom: 20px;
}

.contact-men {
  padding: 80px 0 80px 0;
}
.contact-left {
  /* box-shadow: 0 0 10px #eaeaea; */
  background-color: var(--bs-white);
  border-radius: 10px;
  padding: 30px;
  position: relative;
  border: 1px solid var(--Turquoise-dark);
}
.contact-left::before {
  content: "";
  width: 40%;
  height: 40%;
  background-color: var(--Turquoise-dark);
  position: absolute;
  top: -2%;
  left: -2%;
  z-index: -1;
  border-radius: 10px;
}
.contact-left::after {
  content: "";
  width: 40%;
  height: 40%;
  background-color: var(--Turquoise-dark);
  position: absolute;
  bottom: -2%;
  right: -2%;
  z-index: -1;
  border-radius: 10px;
}
.working-hours-text {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 10px;
}
.working-hours-text h2 {
  font-size: 20px;
  font-weight: bold;
}
.working-hours-text p {
  font-size: 15px;
}
.form-text {
  margin-bottom: 20px;
}
.form-text label {
  font-size: 16px;
  padding-bottom: 5px;
}
.input-btn {
  width: 100%;
  border: 1px solid #e7e7e7;
  padding: 7px;
  border-radius: 5px;
  font-size: 16px;
  text-transform: capitalize;
}
.head-btn1 {
  padding: 10px 50px;
  width: 95%;
}
.input-btn1 {
  padding-bottom: 100px;
}
.contact-right img {
  width: 100%;
  border-radius: 10px;
}
.address-box {
  padding-top: 50px;
}
.address-men {
  padding: 20px;
  background-color: var(--Turquoise-dark);
  text-align: center;
  border-radius: 10px;
}
.address-men i {
  width: 60px;
  height: 60px;
  background-color: var(--bs-white);
  border-radius: 100px;
  color: var(--Turquoise-dark);
  margin-bottom: 20px;
  text-align: center;
  line-height: 65px;
}
.address-men h2 {
  font-size: 17px;
  color: var(--bs-white);
  text-decoration: none;
}
.address-men h2 a {
  font-size: 20px;
  color: var(--bs-white);
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
}
.map {
  margin-top: 50px;
  margin-bottom: 20px;
}
.working-hours-one {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.working-hours-one i {
  background-color: var(--Turquoise-dark);
  width: 50px;
  height: 50px;
  border-radius: 10px;
  color: var(--bs-white);
  line-height: 50px;
  text-align: center;
  margin-right: 15px;
}
.working-hours-one h2 {
  font-size: 17px;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
}
.contact-right {
  margin-left: 50px;
}

.privacy-text {
  border: none;
}
.privacy-text h2 {
  margin-top: 20px;
  margin-bottom: 5px;
  background-color: #f1f1f1;
  padding: 10px 15px;
  border-radius: 2px;
  font-size: 17px;
}
.privacy-text p {
  padding: 0px 15px;
  letter-spacing: 1px;
}
.carousel-indicators [data-bs-target] {
  width: 10px;
  height: 10px;
  background-color: var(--Turquoise-dark);
  border-radius: 100%;
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: -48px;
}
.carousel-control-prev {
  display: none;
}
.carousel-control-next {
  display: none;
}
.privacy-men {
  padding: 80px 0;
}

/* Middle End */

/* Footer Start */

.footer {
  /* background-image: url(../images/footer-bg1.png); */
  background-color: var(--Turquoise-dark);
  background-size: cover;
  background-position: top;
}
.footer-one {
  padding: 60px 0 40px 0;
  /* position: relative; */
}
/* .footer-one::before {
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: red;
  position: absolute;
  top: 0;
  bottom: 0;
} */

.footer-shap {
  position: absolute;
  top: -4%;
  display: none;
}
.footer-text-one img {
  width: 30%;
  margin-bottom: 20px;
  background-color: var(--bs-white);
  padding: 10px;
  border-radius: 5px;
}
.footer-text-one p {
  font-size: 16px;
  color: var(--bs-white);
}
.social-media-footer ul {
  margin: 0;
  padding: 0;
}
.social-media-footer ul li {
  display: inline-block;
  padding: 0 5px;
}
.social-media-footer ul li a {
  background-color: #ffffff1a;
  padding: 10px 0;
  border-radius: 10px 1px;
  width: 35px;
  text-align: center;
  display: inline-block;
  font-size: 15px;
}
.social-media-footer ul li a:hover {
  background-color: var(--bs-dark);
  color: var(--bs-white);
  transition: all 0.4s ease-in-out;
  scale: 0.9;
}
.social-media-footer ul li a i {
  color: var(--bs-white);
}
.footer-text-two h2 {
  font-size: 20px;
  color: var(--bs-white);
  font-weight: bold;
  text-transform: capitalize;
  position: relative;
}
.footer-text-two h2::before {
  content: "";
  background-color: var(--bs-white);
  width: 50px;
  height: 2px;
  position: absolute;
  bottom: -7px;
  left: 0;
}
.footer-text-two ul {
  padding: 0;
  margin: 0;
}
.footer-text-two ul li {
  list-style: none;
  padding: 0 3px;
}
.footer-text-two ul li a {
  text-decoration: none;
  font-size: 15px;
  color: var(--bs-white);
  font-weight: normal;
  font-family: "Raleway", sans-serif;
}
.footer-text-two ul li a::before {
  content: "\27A3 ";
  opacity: 0;
  font-size: 0;
  transition: 0.5s ease-in-out;
}
.footer-text-two ul li a::before {
  opacity: 1;
  font-size: 15px;
  padding-right: 3px;
}
.footer-text-two ul li a:hover:before {
  opacity: 1;
  font-size: 15px;
  color: var(--bs-dark);
}
.footer-text-two ul li a:hover::before {
  padding-left: 7px;
}
.footer-text-two ul li a:hover {
  color: var(--bs-dark);
  transition: 0.5s ease-in-out;
}
.accepted-payment h3 {
  font-size: 15px;
  color: var(--bs-white);
}
.accepted-payment p {
  font-size: 14px;
  color: var(--bs-white);
  padding-top: 10px;
  line-height: 20px;
}
.my-float {
  margin-top: 11px;
}
.float {
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 80px;
  right: 23px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 20px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  text-decoration: none;
}

.float i {
  color: #fff;
}
.accepted-payment p span {
  font-family: Arial, Helvetica, sans-serif;
}
.payment-photo {
  margin-top: 5px !important;
}
.payment-photo li {
  display: inline;
}
.payment-photo li a img {
  width: 10%;
  background-color: var(--bs-white);
  border-radius: 2px;
  padding: 0;
  height: 30px;
}
.payment-photo li a img:hover {
  scale: 1.1;
  transition: all 0.4s ease-in-out;
}
.payment-photo li a svg:hover {
  scale: 1.2;
  transition: all 0.4s ease-in-out;
}
.payment-photo li a svg {
  width: 10%;
  background-color: var(--bs-white);
  border-radius: 2px;
  padding: 5px;
  height: 30px;
}
.payment-photo li a::before {
  display: none;
}
.footer-two {
  padding: 10px 0;
  text-align: center;
  border-top: 1px solid #ffffff21;
  /* background-color: #00978a; */
}
.copyright-text p {
  font-size: 16px;
  color: var(--bs-white);
}
.copyright-text p a {
  font-size: 16px;
  font-weight: bold;
  color: var(--bs-white);
  text-decoration: none;
}

.error-css {
  color: red;
  font-style: italic;
  font-size: 0.875rem;
}

/* Footer End */

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .top-box1 {
    display: block;
    padding-top: 0px;
  }
  .my-float {
    margin-top: 10px;
  }
  .contact-number a {
    font-size: 13px;
  }
  .contact-number {
    text-align: center;
    border: none;
  }
  .social-media-top {
    display: none;
  }
  .head-franchise {
    margin-top: 10px;
  }
  .head-btn {
    margin-left: 0px;
    padding: 5px 30px;
    font-size: 12px;
    margin-right: 5px;
    height: 30px;
  }
  .working-hours-text p {
    font-size: 12px;
    line-height: 16px;
  }
  .form-text label {
    font-size: 12px;
    padding-bottom: 0px;
  }
  .contact-right {
    margin-left: 0px;
    margin-top: 30px;
  }
  .working-hours-one h2 {
    font-size: 13px;
    width: 70%;
  }
  .carousel-indicators [data-bs-target] {
    width: 8px;
    height: 8px;
  }
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -15px;
  }
  .working-hours-one i {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    line-height: 44px;
    font-size: 14px;
  }
  .form-text {
    margin-bottom: 10px;
  }

  .playapp {
    /* display: flex; */
    width: 100%;
    display: inline-block;
  }

  .play {
    display: flex;
    width: 70%;
    display: inline-block;
  }
  .contact-right img {
    width: 100%;
    border-radius: 10px;
    margin-top: 25px;
  }
  .working-hours-one {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .contact-left {
    padding: 15px;
  }
  .address-men {
    margin-bottom: 10px;
  }
  .address-men h2 {
    font-size: 12px;
  }
  .address-men h2 a {
    font-size: 14px;
  }
  .address-men i {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    line-height: 43px;
    font-size: 15px;
  }
  .logo:before {
    display: none;
  }
  .map {
    margin-top: 20px;
  }
  .navbar-brand-one {
    position: inherit;
  }
  .bg-light {
    background-color: var(--Turquoise-dark) !important;
  }
  .logo {
    width: 30%;
  }
  .navbar-light .navbar-toggler {
    border-color: rgb(255 255 255 / 52%);
    padding: 0 10px;
    color: rgb(138 129 129 / 12%);
    background-color: #ffffff85;
  }
  .navbar-light .navbar-toggler-icon {
    width: 16px;
  }
  .nav-link-one {
    background: var(--bs-white) !important;
    margin-left: 0px;
    padding: 8px 20px !important;
    font-size: 14px;
    color: var(--Turquoise-dark) !important;
    margin: 7px;
  }
  ul.navbar-nav.m-auto.mb-2.mb-lg-0 {
    margin-top: 15px !important;
    border-top: 1px solid var(--bs-white);
  }
  .head-franchise {
    display: flex;
    justify-content: center;
  }
  .banner-text h2 {
    font-size: 24px;
  }
  .banner-men {
    position: absolute;
    top: 7%;
  }
  /* .footer-shap {
      position: absolute;
      top: -0.3%;
  }     */
  .banner-text p {
    font-size: 13px;
    padding: 10px 0 15px 0;
    color: #3f3f3f;
    line-height: 20px;
  }
  .about-men::before {
    display: none;
  }
  .btn1 a img {
    border: none;
    padding: 0;
    border-radius: 2px;
    width: 40%;
  }
  .all-btn {
    margin-bottom: 15px;
  }
  .banner-text-one {
    text-align: center !important;
  }
  .banner-text-one img {
    width: 65%;
    margin-top: 0px;
  }
  .about-men {
    padding: 40px 0;
  }
  .contact-men {
    padding: 40px 0 40px 0;
  }
  .privacy-men {
    padding: 40px 0;
  }
  .privacy-text h2 {
    font-size: 14px;
  }
  .about-text h2 {
    font-size: 24px;
  }
  .about-text p {
    font-size: 13px;
    color: #3f3f3f;
    line-height: 20px;
  }
  .welcome-text {
    padding-bottom: 10px;
  }
  .banner-text-car {
    text-align: center !important;
    margin-top: 20px;
  }
  .heading-men h2 {
    font-size: 24px;
  }
  .heading-men p {
    font-size: 14px;
    line-height: 18px;
  }
  .modile-box1 {
    display: none;
  }
  .mobile-marg {
    margin-top: 20px !important;
  }
  .how-works-one {
    position: inherit;
    border-left: 5px solid #61a7ba;
  }
  .how-works-rignt {
    border: none;
    border-right: 5px solid #61a7ba;
    border-left: 5px solid #61a7ba;
  }
  .how-works-one h2 {
    font-size: 14px;
    padding-top: 10px;
    width: 90%;
    margin: 0 auto;
  }
  .how-works-one img {
    width: 50px;
    height: 50px;
  }
  .middle-banner {
    padding: 0px;
  }
  .how-works-one:hover:before {
    display: none;
  }
  .how-works-one:hover:after {
    display: none;
  }
  .our-services-men {
    padding: 40px 0;
  }
  .heading-men {
    padding-bottom: 15px;
  }
  .our-services-one {
    padding: 20px 20px 30px 20px;
    margin-bottom: 15px;
    height: auto;
  }

  .col-3 {
    width: 40%;
  }
  .how-works-active :before {
    display: none;
  }
  .how-works-active :after {
    display: none;
  }
  .why-chooseus-men {
    padding: 30px 0;
  }
  .why-chooseus-one-photo img {
    border-radius: 10px;
    margin-bottom: 25px;
  }
  .why-chooseus-one h2 {
    font-size: 15px;
    padding-top: 10px;
  }
  .blog-men {
    padding: 40px 0;
  }
  .blog-men-text {
    margin-bottom: 20px;
  }
  .app-icon-photo {
    width: 25%;
    margin-bottom: 10px;
  }
  .At-fingertips-men {
    padding: 40px 0;
  }
  .btn1 {
    text-align: center;
    margin-bottom: 10px;
  }
  .btn2 img {
    width: 40% !important;
  }
  .footer-text-two {
    margin-top: 20px;
  }
  .footer {
    background-color: #00978a;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .social-media-top ul li {
    padding: 0 2px;
  }
  .social-media-top ul li a i {
    font-size: 13px;
  }
  .contact-number a {
    font-size: 13px;
  }
  .number-text {
    margin-right: 5px;
  }
  .head-btn {
    padding: 5px 25px;
    font-size: 12px;
  }
  .logo:before {
    height: 120%;
    display: none;
  }
  .logo {
    width: 8%;
  }
  .navbar-brand-one {
    position: inherit;
  }
  .top-box1 {
    justify-content: start;
  }
  .privacy-men {
    padding: 40px 0;
  }

  .logo {
    width: 15%;
  }
  /* .footer-shap {
      position: absolute;
      top: -2%;
  } */
  .navbar-light .navbar-toggler {
    border-color: rgb(255 255 255 / 52%);
    padding: 0 10px;
    color: rgb(138 129 129 / 12%);
    background-color: #ffffff85;
  }

  .col-3 {
    width: 40%;
  }

  .working-hours-one i {
    width: 35px;
    height: 30px;
    border-radius: 4px;
    line-height: 32px;
    font-size: 14px;
  }

  
  .play {
    display: flex;
    /* width: 70%; */
    display: inline-block;
  }
  .contact-right {
    margin-left: 0px;
    margin-top: 30px;
  }
  .payment-photo li a img {
    height: 18px;
  }
  .payment-photo li a svg {
    height: 18px;
  }
  .navbar-light .navbar-toggler-icon {
    width: 16px;
  }
  .nav-link-one {
    background: var(--bs-white) !important;
    margin-left: 0px;
    padding: 8px 20px !important;
    font-size: 14px;
    color: var(--Turquoise-dark) !important;
    margin: 7px;
  }
  .about-men::before {
    display: none;
  }
  .bg-light {
    background-color: var(--Turquoise-dark) !important;
  }
  .address-men h2 {
    font-size: 12px;
  }
  .address-men i {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
    line-height: 52px;
  }
  .address-men h2 a {
    font-size: 15px;
  }
  .banner-text h2 {
    font-size: 25px;
  }
  .contact-men {
    padding: 40px 0 40px 0;
  }
  .form-text label {
    font-size: 12px;
    padding-bottom: 2px;
  }
  .input-btn {
    padding: 3px 7px;
    font-size: 11px;
  }
  .form-text {
    margin-bottom: 5px;
  }
  .contact-right img {
    margin-top: 30px;
  }
  .heading-men h2 {
    font-size: 25px;
  }
  .heading-men p {
    font-size: 15px;
  }
  .banner-text p {
    font-size: 14px;
    padding: 10px 0 0px 0;
    line-height: 22px;
  }
  .banner-men {
    position: absolute;
    top: 10%;
    align-items: center;
  }
  .bg-video {
    height: 400px;
  }
  .banner-text-one {
    width: 80%;
    margin: 0 auto;
  }
  .banner-text-car {
    display: flex;
    align-items: end;
    height: 100%;
  }
  .how-works-one h2 {
    font-size: 12px;
    width: 95%;
    margin: 0 auto;
  }
  .our-services-one h2 {
    font-size: 13px;
  }
  .our-services-one {
    padding: 20px 5px 30px 5px;
    height: 200px;
    margin-bottom: 20px;
  }
  .our-services-men {
    padding: 50px 0;
  }
  .why-chooseus-men {
    padding: 50px 0;
  }
  .our-services-one p {
    font-size: 11px;
    line-height: 16px;
    padding-top: 4px;
  }
  .why-chooseus-one-photo {
    text-align: center;
  }
  .why-chooseus-one p {
    font-size: 10px;
    line-height: 15px;
  }
  .why-chooseus-one {
    padding: 20px 10px;
    /* margin-top: 20px ! IMPORTANT; */
  }
  .why-chooseus-one img {
    width: 35px;
  }
  .why-chooseus-one-photo img {
    /* object-fit: cover; */
    height: 565px;
  }

  .our-services-one::before {
    content: "";
    width: 30px;
    height: 30px;
  }
  .our-services-one::after {
    content: "";
    width: 30px;
    height: 30px;
  }
  .blog-men-text {
    margin-bottom: 20px;
  }
  .btn1.btn2 {
    text-align: center;
    margin-bottom: 5px;
  }

  .working-hours-one {
    margin-top: 10px;
  }

  .contact-left {
    padding: 15px;
  }

  .working-hours-one h2 {
    font-size: 13px;
  }

  /* .btn2 a img {
    width: 80%;
  } */
  .btn1 a img {
    border: none;
    padding: 0;
    border-radius: 2px;
    width: 100%;
    margin: 10px 0px 0px 0px ;
  }
  .app-icon-photo {
    width: 12%;
  }
  .At-fingertips-men {
    padding: 50px 0;
  }
  .blog-men {
    padding: 50px 0;
  }
  .footer-text-two ul li a {
    font-size: 11px;
    padding: 2px;
  }
  .footer-text-two ul li {
    padding: 0;
  }
  .footer-text-one p {
    font-size: 13px;
    line-height: 16px;
  }
  .accepted-payment p {
    font-size: 13px;
  }
  .accepted-payment h3 {
    font-size: 13px;
  }
  .copyright-text p {
    font-size: 12px;
  }
  .copyright-text p a {
    font-size: 12px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1400px) {
  .contact-number a {
    font-size: 14px;
  }
  .social-media-top ul li a i {
    font-size: 12px;
  }
  .logo:before {
    width: 220px;
    height: 116%;
  }
  .nav-link-one {
    padding: 8px 20px !important;
    font-size: 12px;
  }
  .logo {
    width: 8%;
  }
  .banner-men {
    position: absolute;
    top: 15%;
  }
  .how-works-one h2 {
    font-size: 15px;
    width: 90%;
    margin: 0 auto;
  }

  .why-chooseus-one-photo img {
    /* object-fit: cover; */
    height: 620px;
  }
  .our-services-one {
    padding: 20px 10px 30px 10px;
    height: auto;
  }
  .col-3 {
    width: 35%;
  }

  .btn1 a {
    padding: 10px 20px;
  }

  .btn1 a img {
    border: none;
    padding: 0;
    border-radius: 2px;
    width: 100%;
  }

  .our-services-one p {
    font-size: 12px;
    line-height: 18px;
  }
  .about-men::before {
    content: "";
    border-top: 480px solid transparent;
  }
  .why-chooseus-one p {
    font-size: 12px;
    line-height: 16px;
  }
  .why-chooseus-men {
    padding: 50px 0;
  }
  .At-fingertips-men {
    padding: 50px 0;
  }
  .footer-text-two ul li a {
    font-size: 13px;
    padding: 2px;
  }
  /* .footer-shap {
      position: absolute;
      top: -3%;
  } */
  .footer-text-one p {
    font-size: 13px;
    line-height: 18px;
  }

  .footer-text-two ul li {
    padding: 0 0px !important;
  }
  .accepted-payment p {
    font-size: 13px;
  }
  .copyright-text p {
    font-size: 12px;
  }
  .copyright-text p a {
    font-size: 12px;
  }
  .social-media-top ul li a {
    padding: 3px 8px;
  }

  
  .play {
    display: flex;
    width: 32%;
    display:flex;
    gap: 10px;
  }
}

.nav-item > a.active a {
  background-color: var(--Turquoise);
}
